import React from 'react'
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Section from '../../../../resuable/section/section'
import BlogCard from '../../../../resuable/blog/blog-card/blog-card'
import BlogCardsContainer from '../../../../resuable/blog/blog-cards-container/blog-cards-container'

const StyledBlogCategorySection = styled.div`
  div.container__heading {}
  div.container__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  div.container__viewall {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    justify-content: flex-end;
    a {
      font-size: 16px;
    }
  }
  @media (max-width: 768px) {
    padding: 0 20px 0 20px;
  }
`

const BlogCategorySection = ({ categoryName }) => {
  const data = useStaticQuery(graphql`
  query categoryPosts {
      allWordpressPost(sort: {order: DESC, fields: date}, limit: 6, filter: {categories: {elemMatch: {name: {eq: "Digital Marketing"}}}}) {
        edges {
          node {
            id
            title
            content
            slug
            date
            featured_media {
              source_url
              localFile {
                relativePath
              }
            }
            author {
              name
              avatar_urls {
                wordpress_96
              }
              mpp_avatar {
                wordpress_96
              }
            }
            categories {
              name
            }
          }
        }
      }
    }
  `)
  const { edges } = data.allWordpressPost;
  return (
    <Section
      padding="0px 20px 40px"
    >
      <StyledBlogCategorySection>
        <div className="container__heading">
          <h3>Digital Marketing</h3>
        </div>
        <BlogCardsContainer>
          {
            edges.map((edge, index) => {
              const { title, content, slug, featured_media, categories, author } = edge.node;
              return (
                <BlogCard
                  key={index}
                  title={title}
                  content={content}
                  slug={slug}
                  featured_media={featured_media}
                  categories={categories}
                  author={author}
                />
              )
            })
          }
        </BlogCardsContainer>
        <div className="container__viewall">
          <Link to={`a2i-world/category/digital-marketing`}>
            View All
        </Link>
        </div>
      </StyledBlogCategorySection>
    </Section>
  )
}

BlogCategorySection.defaultProps = {
  categoryName: 'Digital Marketing'
}

export default BlogCategorySection