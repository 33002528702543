import React from 'react'
import styled from 'styled-components'
import Section from '../../../../resuable/section/section'
import BlogCard from '../../../../resuable/blog/blog-card/blog-card'
import { Link, useStaticQuery, graphql } from 'gatsby'
import BlogCardsContainer from '../../../../resuable/blog/blog-cards-container/blog-cards-container'

const StyledBlogLatestSection = styled.div`
  div.container__heading {}
  div.container__viewall {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    justify-content: flex-end;
    a {
      font-size: 16px;
    }
  }
  
  @media (max-width: 768px) {
    padding: 20px;
    div.container__list {
      flex-direction: column;
      align-items: center;
    }
  }
`

const BlogLatestSection = (props) => {
  const data = useStaticQuery(graphql`
    query latestPosts {
      allWordpressPost(
        sort: {order: DESC, fields: date},
        filter: {acf: {isTopPost: {eq: true}}},
        limit: 4
      ) {
        edges {
          node {
            id
            title
            content
            slug
            date
            featured_media {
              source_url
            }
            author {
              name
              avatar_urls {
                wordpress_96
              }
              mpp_avatar {
                wordpress_96
              }
            }
            categories {
              name
            }
            acf {
              isTopPost
            }
          }
        }
      }
    }
  `)
  const { edges } = data.allWordpressPost;
  return (
    <Section
      padding="0px 20px 40px"
    >
      <StyledBlogLatestSection>
        <div className="container__heading">
          <h3>Popular this Week</h3>
        </div>
        <BlogCardsContainer>
          {
            edges.map((edge, index) => {
              const { title, content, slug, featured_media, categories, author } = edge.node;
              return (
                <BlogCard
                  key={index}
                  featured={index === 0}
                  title={title}
                  content={content}
                  slug={slug}
                  featured_media={featured_media}
                  categories={categories}
                  author={author}
                />
              )
            })
          }
        </BlogCardsContainer>
        <div className="container__viewall">
          <Link to={`/a2i-world/all/`}>
            View All
          </Link>
        </div>
      </StyledBlogLatestSection>
    </Section>
  )
}

BlogLatestSection.defaultProps = {

}

export default BlogLatestSection