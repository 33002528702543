import React from "react"
import styled from "styled-components"
import Row from "../../../../resuable/row/row"
import A2iResource from "../../../../resuable/a2i-resource/a2i-resource"
import { useStaticQuery, graphql, Link } from "gatsby"
import Section from "../../../../resuable/section/section"
import { ButtonType } from "../../../../resuable/button"

const StyledBlogResourcesSection = styled.div`
  width: 100%;
  padding: 60px 20px;
  .heading {
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
    h2 {
      margin-bottom: 14px;
    }
    h2, p {
      text-align: center;
    }
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const BlogResourcesSection = props => {
  const data = useStaticQuery(graphql`
    query allResourcesUptoFive {
      allWordpressWpA2IResources(limit: 5) {
        edges {
          node {
            id
            title
            content
            excerpt
            slug
            categories {
              name
            }
            author {
              name
            }
            featured_media {
              source_url
            }
            acf {
              link
            }
          }
        }
      }
    }
  `)

  const { edges: resources } = data.allWordpressWpA2IResources

  return (
    <Section>
      <StyledBlogResourcesSection>
        <Row className="heading">
          <h2>A2i Resources</h2>
          <p>Curated list of mindblowing resources to help you go from zero to one!</p>
        </Row>
        <Row className="content">
          {
            resources.length !== 0 ? (
              resources.map(resource => {
                const {
                  id,
                  title,
                  content,
                  excerpt,
                  slug,
                  author,
                  categories,
                  featured_media,
                  acf,
                } = resource.node
                console.log(featured_media)
                return (
                  <A2iResource
                    key={id}
                    link={acf.link}
                    title={title}
                    content={content}
                    excerpt={excerpt}
                    slug={slug}
                    categories={categories}
                    author={author}
                    featured_media={featured_media}
                  />
                )
              })
            ) : null
          }
        </Row>
        <Row>
          <Link to="/a2i-world/resources">
            <ButtonType.Ghost>View More</ButtonType.Ghost>
          </Link>
        </Row>
      </StyledBlogResourcesSection>
    </Section>
  )
}

BlogResourcesSection.defaultProps = {}

export default BlogResourcesSection
