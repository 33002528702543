import React from 'react'
import Layout from "../components/resuable/layout/layout";
import BlogLatestSection from '../components/page/blog-components/blog-main-page/blog-latest-section/blog-latest-section'
import BlogTopGrossingSection from '../components/page/blog-components/blog-main-page/blog-topgrossing-section/blog-topgrossing-section'
import BlogCategorySection from '../components/page/blog-components/blog-main-page/blog-category-section/blog-category-section'
import Joinus from '../components/resuable/joinus/joinus'
import BlogHeroSection from '../components/page/blog-components/blog-main-page/blog-hero-section/blog-hero-section'
import useWindowSize from '../components/hooks/useWindowSize';
import BlogResourcesSection from '../components/page/blog-components/blog-main-page/blog-resources-section/blog-resources-section';

const Blog = () => {
  const size = useWindowSize();
  return (
    <Layout
      isBlogMenuActive={size.width > 768}
      theme="dark"
    >
      <BlogHeroSection />
      <BlogLatestSection />
      <BlogTopGrossingSection />
      <BlogCategorySection />
      <BlogResourcesSection />
      <Joinus />
    </Layout>
  )
}

export default Blog
