import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import Hero from '../../../../resuable/hero/hero'
import SEO from '../../../../resuable/seo/seo'

const StyledBlogHeroSection = styled.div`
  width: 100%;
`

const BlogHeroSection = (props) => {
  const data = useStaticQuery(graphql`
    query blogMainSEO {
      wordpressPage(slug: {eq: "a2i-world"}) {
        acf {
          hero_section {
            page_subtitle
            page_title
          }
        }
        yoast_meta {
          yoast_wpseo_title
          yoast_wpseo_twitter_description
          yoast_wpseo_metadesc
        }
      }
    }  
  `)
  const { acf, yoast_meta } = data.wordpressPage;
  return (
    <StyledBlogHeroSection>
      <SEO 
        title={yoast_meta.yoast_wpseo_title}
        description={yoast_meta.yoast_wpseo_twitter_description}
      />
      <Hero 
        title={acf.hero_section.page_title}
      >
        <h2>{acf.hero_section.page_subtitle}</h2>
      </Hero>
    </StyledBlogHeroSection>
  )
}

BlogHeroSection.defaultProps = {

}

export default BlogHeroSection