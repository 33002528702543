import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const StyledBlogSlide = styled.div`
  position: relative;
  height: 480px;
  width: 100%;
  max-width: 100%;
  img {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(20%);
    transition: 0.6s all;
  }
  h4 {
    position: absolute;
    left: 10px;
    bottom: 10px;
  }
  &:hover {
    img {
      transition: 0.6s all;
      filter: brightness(50%);
    }
  }
`

const BlogSlide = ({
  title,
  slug,
  featured_media
}) => {
  return (
    <StyledBlogSlide>
      <Link to={`/a2i-world/${slug}`}>
        {
          featured_media !== null ? (
            <img src={featured_media.source_url} alt="featured Media" />
          ) : (
              <img src="https://source.unsplash.com/1280x720/?nature,water" alt="featured Media" />
            )
        }
        <h4>{title}</h4>
      </Link>
    </StyledBlogSlide>
  )
}

BlogSlide.defaultProps = {
  title: 'lorem ipsum dolar set amet'
}

export default BlogSlide