import React from "react"
import styled from "styled-components"
import { placeholderImage } from "../../../utils/helpers/generator"
import { Link } from "gatsby"
import parse from 'html-react-parser';

const StyledA2iResource = styled(Link)`
  box-sizing: border-box;
  width: 100%;
  max-width: 650px;
  margin-bottom: 30px;
  padding: 20px;
  display: flex;
  justify-content: center;
  background: #222222;
  border: 1px solid #525252;
  .left {
    margin-right: 20px;
    flex: 1 1 70%;
    h3 {
      font-size: 24px;
    }
    div.excerpt {
      margin-bottom: 10px;
      color: #dcdcdc;
      font-size: 14px;
    }
    .meta {
      span.category {
        margin-right: 10px;
        padding: 4px 8px;
        background: #dfdfdf;
        color: #444;
        font-size: 8px;
        line-height: 100%;
      }
      span.author {
        color: #444;
        font-size: 10px;
        line-height: 100%;
      }
    }
  }
  .right {
    margin-left: 20px;
    flex: 1 1 30%;
    img {
      height: 150px;
      width: 150px;
      object-fit: cover;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    .left {
      margin: 0px;
      margin-bottom: 20px;
      flex: 1 1 100%;
    }
    .right {
      margin: 0px;
      flex: 1 1 100%;
      img {
        width: 100%;
        height: 200px;
      }
    }
  }
`

const A2iResource = ({
  title,
  slug,
  content,
  excerpt,
  featured_media,
  author,
  categories,
  ...props
}) => {
  console.log(categories);
  return (
    <StyledA2iResource to={`/a2i-world/resources/${slug}`}>
      <div className="left">
        <h3>{parse(title)}</h3>
        <div 
          dangerouslySetInnerHTML={{ __html: excerpt }}
          className="excerpt"
        />
        <div className="meta">
          {/* Tags */}
          {/* Check if categories is not empty */}
          {
            categories !== null ? (
              categories.map((category, index) => {
                return (
                  <span key={index} className="category">{category.name}</span>
                )
              })
            ) : (
              <span className="category">Uncategorized</span>
            )
          }
          {/* Submitter */}
          <span className="author">Submitted by {author.name}</span>
        </div>
      </div>
      <div className="right">
        <img src={featured_media === null ? placeholderImage(200, 200) : featured_media.source_url} alt="thumbnail"/>
      </div>
    </StyledA2iResource>
  )
}

A2iResource.defaultProps = {
  title: "UX design process: a simple (but complete) guide",
  content:
    "Discover the UX design process needed to create effective and memorable user experiences that actually matter.",
  excerpt: "This is an amazing article",
  featured_media: "",
  categories: [
    'ux'
  ],
  author: {
    data: {
      display_name: 'Omar'
    }
  },
  wordpress_id: 2100
}

export default A2iResource
