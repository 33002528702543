import React, { useState } from 'react'
import styled from 'styled-components'
import Section from '../../../../resuable/section/section'
import BlogSlide from '../../../../resuable/blog/blog-slide/blog-slide'

import ItemsCarousel from 'react-items-carousel';
import { Link, useStaticQuery, graphql } from 'gatsby';
import useWindowSize from '../../../../hooks/useWindowSize';

const StyledBlogTopGrossingSection = styled.div`
  position: relative;
  width: 100%;
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  div.container__slides {
    width: 100%;
    overflow: hidden;
    padding: 0px 0px;
    .wrapper {
      
    }
    .itemsWrapper {
      overflow-y: hidden;
    }
    .rightChevronWrapper, .leftChevronWrapper {
      button {
        height: 30px;
        width: 30px;
        background: #232323;
        color: #fff;
        border-radius: 50%;
      }
    }
  }
  div.container__viewall {
    width: 100%;
    padding: 20px 0px 10px;
    display: flex;
    justify-content: flex-end;
    a {
      font-size: 16px;
    }
  }
  @media (max-width: 768px) {
    padding: 20px;
    div.container__list {
      flex-direction: column;
      align-items: center;
    }
  }
`

const BlogTopGrossingSection = ({
  posts
}) => {
  const data = useStaticQuery(graphql`
    query topPosts {
      allWordpressPost(sort: {order: DESC, fields: date}, limit: 4) {
        edges {
          node {
            id
            title
            slug
            date
            featured_media {
              source_url
              localFile {
                relativePath
              }
            }
            author {
              name
            }
          }
        }
      }
    }  
  `);
  const { edges } = data.allWordpressPost;
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  const { width } = useWindowSize();
  return (
    <Section
      fullwidth={false}
      padding="0px 20px 20px"
    >
      <StyledBlogTopGrossingSection>
        <div className="container__heading">
          <h3>Most Talked About</h3>
        </div>
        <div className="container__slides">
          <ItemsCarousel
            classes={{
              wrapper: 'wrapper',
              itemsWrapper: 'itemsWrapper',
              itemsInnerWrapper: 'itemsInnerWrapper',
              itemWrapper: 'itemWrapper',
              rightChevronWrapper: 'rightChevronWrapper',
              leftChevronWrapper: 'leftChevronWrapper'
            }}
            infiniteLoop={true}
            gutter={0}
            firstAndLastGutter={false}
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={width >= 768 ? 3 : 1}
            slidesToScroll={1}
            disableSwipe={false}
            showSlither={true}
            leftChevron={<button>{'<'}</button>}
            rightChevron={<button>{'>'}</button>}
            outsideChevron={false}
            chevronWidth={chevronWidth}
          >
            {
              edges.map((edge, index) => {
                const { title, slug, featured_media } = edge.node
                return (
                  <BlogSlide 
                    key={index}
                    title={title}
                    slug={slug}
                    featured_media={featured_media}
                  />
                )
              })
            }
          </ItemsCarousel>
        </div>
        <div className="container__viewall">
          <Link to={`/a2i-world/top`}>
            View Top
          </Link>
        </div>
      </StyledBlogTopGrossingSection>
    </Section>
  )
}

BlogTopGrossingSection.defaultProps = {

}

export default BlogTopGrossingSection