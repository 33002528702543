import React from 'react'
import styled from 'styled-components'

const StyledBlogCardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const BlogCardsContainer = ({ children }) => {
  return (
    <StyledBlogCardsContainer>
      {children}
    </StyledBlogCardsContainer>
  )
}

BlogCardsContainer.defaultProps = {

}

export default BlogCardsContainer